import React from 'react';
import styled from "styled-components"
import cloudinaryOptimize from '../../helpers/cloudinaryOptimize'

const DoubleImageContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const LargeImage = styled.div`
  width: 100%;
  @media (min-width: ${({theme}) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`

const SmallImage = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0;
    width: 50%;
  }
`

const DoubleImage = ({imageBaseUrl}) => {
    return (
      <DoubleImageContainer>
        <LargeImage>
          <img
            src={cloudinaryOptimize(`${imageBaseUrl}/double-image-left.jpg`,1800)}
            alt="Large Brewery Image Left"
          />
        </LargeImage>
        {/* <SmallImage>
          <img
            src={cloudinaryOptimize(`${imageBaseUrl}/double-image-right.jpg`, 225)}
            alt="Small brewery Image"
          />t
        </SmallImage> */}
      </DoubleImageContainer>
    )
}

export default DoubleImage;
